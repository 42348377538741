import request from './request'

export function getReviews(data) {
    return request({
        url: `dashboard/reviews?page=${data.page}&per_page=${data.per_page}`,
        method: 'get'
    })
}

export function review(id) {
    return request({
        url: 'dashboard/reviews/' + id,
        method: 'get'
    })
}

export function deleteReview(id) {
    return request({
        url: 'dashboard/reviews/' + id,
        method: 'delete'
    })
}