<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref, reactive } from "vue";
import * as reviewsAPI from "@/http/review.js";
import { ElNotification } from "element-plus";
import moment from "@/helpers/moment";
import { Search } from "@element-plus/icons-vue";
import { Icon } from "@iconify/vue";

const loading = ref(false);
const tableData = ref([]);
const paginationData = reactive({
  lastPage: "",
  from: "",
  to: "",
  total: "",
  page: 1,
  per_page: 10,
  currentPage: "",
});

onMounted(() => {
  fetchReviews();
});

const fetchReviews = async () => {
  try {
    loading.value = true;
    const response = await reviewsAPI.getReviews(paginationData);
    tableData.value = response.data;
    paginationData.lastPage = response.last_page;
    paginationData.from = response.from;
    paginationData.to = response.to;
    paginationData.total = response.total;
    paginationData.currentPage = response.current_page;
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les avis n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const delReview = async (id) => {
  try {
    loading.value = true;
    await reviewsAPI.deleteReview(id);
    fetchReviews();
    ElNotification({
      title: "Succèss",
      message: "Avis supprimé !",
      type: "success",
    });
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Veuillez réessayer",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-uppercase">
          <a href="#">Findlove</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Avis
        </li>
      </ol>
    </nav>
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->

  <div class="row">
    <div
      v-for="(item, index) in tableData"
      :key="index"
      class="col p-4 ps-3 m-2 avis-card d-flex flex-column"
      style="max-width: 300px"
    >
      <div class="mb-3 d-flex justify-content-between">
        <span>
          <Icon icon="bx:bx-time-five" class="me-2" width="15" :inline="true" />
          <time datetime="10/12/20T16h34"
            >{{ moment(item.created_at).format("DD MMMM YYYY à HH:mm") }}
          </time>
        </span>
      </div>
      <h4 class="mb-3" style="color: #5b5c60">
        {{ item.user.name }}
      </h4>
      <span class="mb-3">
        <Icon class="me-2" icon="carbon:email" width="15" :inline="true" />
        <span>{{ item.user.email }} </span>
      </span>
      <span>{{ item.comment }}</span>
      <Button class="p-button-sm p-button-danger" @click="delReview(item?.id)"
        >Supprimer</Button
      >
    </div>
    <div class="mt-3 d-flex justify-content-between align-items-center">
      <span class="mb-0">
        Affichage de {{ paginationData.from }} à {{ paginationData.to }} sur un
        total de {{ paginationData.total }}
      </span>
      <nav aria-label="Page navigation">
        <ul class="pagination pagination-sm mb-0">
          <li
            v-for="(page, index) in paginationData.lastPage"
            :key="index"
            class="page-item"
            :class="paginationData.currentPage == page ? 'active' : null"
          >
            <a
              class="page-link pointer"
              @click="(paginationData.currentPage = page), fetchReviews(page)"
              >{{ page }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
}

.label {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(38, 150, 190, 0.2);
  color: rgb(38, 150, 190);
}

.avis-card {
  background: linear-gradient(270deg, #ffffff 97.14%, #fab917 97.53%);
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
    0px 8px 16px rgba(96, 97, 112, 0.1);
  border-radius: 8px;
  width: 18.75rem;
}

.p-rating .p-rating-icon.pi-star {
  color: #fab917;
}
</style>
